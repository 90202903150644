import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import ChecklistResourcesSection from "../../../components/ChecklistResourcesSection/ChecklistResourcesSection.jsx";
import { checklists } from "../../../layout/Constant.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ChecklistResourcesSection figmaUrl={checklists.componentAvatar.figmaUrl.android} codeUrl={checklists.componentAvatar.codeUrl.android} checklists={checklists.componentAvatar.checklists} mdxType="ChecklistResourcesSection" />
    <div className="divi" />
    <p>{`An avatar is a graphical representation of an object or entity, such as a person or organization.`}</p>
    <div {...{
      "className": "line",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-one",
        "align": "center"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "align": "center",
            "width": 250,
            "height": 500,
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/avatar/Avatar_android.gif",
            "alt": "avatar android"
          }}></img>{`
    `}</div>{`
  `}</div>
    </div>
    <div className="divi" />
    <h2>{`Variants`}</h2>
    <p>{`Legion has 6 variants of Avatar :`}</p>
    <ol>
      <li parentName="ol">{`Avatar with Badge Dot`}</li>
      <li parentName="ol">{`Avatar with Badge Icon`}</li>
      <li parentName="ol">{`Avatar with Badge Number`}</li>
      <li parentName="ol">{`Avatar Image Url`}</li>
      <li parentName="ol">{`Avatar Initial Text`}</li>
      <li parentName="ol">{`Avatar Image Icon`}</li>
    </ol>
    <p>{`and also have three size :`}</p>
    <ol>
      <li parentName="ol">{`Avatar Large`}</li>
      <li parentName="ol">{`Avatar Medium`}</li>
      <li parentName="ol">{`Avatar Small`}</li>
    </ol>
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`To implement Avatar, you can follow the steps below.`}</p>
    <h3>{`Avatar with Badge Dot`}</h3>
    <h4>{`Define widget in your xml/programatically`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.avatar.LgnAvatarMedium
    android:id="@+id/lamAvatarBadgeDot"
    ... />

`}</code></pre>
    <h4>{`Define widget in your fragment/activity. This is sample avatar initial text with badge dot`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
binding.lamAvatarBadgeDot.apply {
    avatar = LgnAvatarInitialMedium(context).apply {
        text = "Rizki Ilhami"
        textColor = context.requiredColor(UiKitAttrs.colorPrimary500)
    }
    badge = LgnAvatarBadgeMedium(context).apply {
        color = ContextCompat.getColor(context, android.R.color.holo_green_dark)
    }
}

...
`}</code></pre>
    <h3>{`Avatar with Badge Icon`}</h3>
    <h4>{`Define widget in your xml/programatically`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.avatar.LgnAvatarMedium
    android:id="@+id/lamAvatarBadgeIcon"
    ... />

`}</code></pre>
    <h4>{`Define widget in your fragment/activity. This is sample avatar initial text with badge icon`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
binding.lamAvatarBadgeIcon.apply {
    avatar = LgnAvatarInitialMedium(context).apply {
        text = "Rizki Ilhami"
        textColor = context.requiredColor(UiKitAttrs.colorPrimary500)
    }
    badge = LgnAvatarBadgeIconMedium(context).apply {
        icon = ContextCompat.getDrawable(context, R.drawable.ic_camera_16)
        color = ContextCompat.getColor(context, android.R.color.holo_green_dark)
    }
}
...
`}</code></pre>
    <h3>{`Avatar with Badge Number`}</h3>
    <h4>{`Define widget in your xml/programatically`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.avatar.LgnAvatarMedium
    android:id="@+id/lamAvatarBadgeNumber"
    ... />


`}</code></pre>
    <h4>{`Define widget in your fragment/activity. This is sample avatar initial text with badge number`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
binding.lamAvatarBadgeNumber.apply {
    avatar = LgnAvatarInitialMedium(context).apply {
        text = "Rizki Ilhami"
        textColor = context.requiredColor(UiKitAttrs.colorPrimary500)
    }
    badge = LgnAvatarBadgeNumberMedium(context).apply {
        number = 10
        color = ContextCompat.getColor(context, android.R.color.holo_green_dark)
    }
}

...
`}</code></pre>
    <h3>{`Avatar Image Url`}</h3>
    <h4>{`Define widget in your xml/programatically`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.avatar.LgnAvatarMedium
    android:id="@+id/lamAvatarImageUrl"
    ... />

`}</code></pre>
    <h4>{`Define widget in your fragment/activity. This is sample avatar initial text with badge url`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
binding.lamAvatarImageUrl.apply {
    avatar = LgnAvatarImageMedium(context).apply {
        loadImage(
            imageUrl = "https://i.pravatar.cc/40",
            placeholder = ContextCompat.getDrawable(context, R.drawable.ic_placeholder_avatar)
        )
    }
    badge = LgnAvatarBadgeMedium(context).apply {
        color = ContextCompat.getColor(context, android.R.color.holo_green_dark)
    }
}

...
`}</code></pre>
    <h3>{`Avatar Initial Text`}</h3>
    <h4>{`Define widget in your xml/programatically`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.avatar.LgnAvatarMedium
    android:id="@+id/lamAvatarInitialText"
    ... />


`}</code></pre>
    <h4>{`Define widget in your fragment/activity.`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
binding.lamAvatarInitialText.apply {
    avatar = LgnAvatarInitialMedium(context).apply {
        text = "Rizki Ilhami"
    }
    badge = LgnAvatarBadgeMedium(context).apply {
        color = ContextCompat.getColor(context, android.R.color.holo_green_dark)
    }
}


...
`}</code></pre>
    <h3>{`Avatar Image Icon`}</h3>
    <h4>{`Define widget in your xml/programatically`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.avatar.LgnAvatarMedium
    android:id="@+id/lamAvatarIcon"
    ... />

`}</code></pre>
    <h4>{`Define widget in your fragment/activity.`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
binding.lamAvatarInitialText.apply {
    avatar = LgnAvatarIconMedium(context).apply {
        icon = ContextCompat.getDrawable(context, R.drawable.ic_camera_16)
    }
    badge = LgnAvatarBadgeMedium(context).apply {
        color = ContextCompat.getColor(context, android.R.color.holo_green_dark)
    }
}

...
`}</code></pre>
    <h3>{`Avatar Icon`}</h3>
    <h4>{`Define widget in your xml/programatically`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.avatar.LgnAvatarMedium
    android:id="@+id/lamAvatarIcon"
    ... />


`}</code></pre>
    <h4>{`Define widget in your fragment/activity.`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
binding.lamAvatarInitialText.apply {
    avatar = LgnAvatarIconMedium(context).apply {
        icon = ContextCompat.getDrawable(context, R.drawable.ic_camera_16)
    }
    badge = LgnAvatarBadgeMedium(context).apply {
        color = ContextCompat.getColor(context, android.R.color.holo_green_dark)
    }
}


...
`}</code></pre>
    <h3>{`Avatar Large`}</h3>
    <h4>{`Define widget in your xml/programatically`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.avatar.LgnAvatarLarge
    android:id="@+id/lamAvatarLarge"
    ... />

`}</code></pre>
    <h4>{`Define widget in your fragment/activity.`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
binding.lamAvatarLarge.apply {
    avatar = LgnAvatarInitialLarge(context).apply {
        text = "Rizki Ilhami"
        textColor = context.requiredColor(UiKitAttrs.white)
    }
    badge = LgnAvatarBadgeLarge(context).apply {
        color = ContextCompat.getColor(context, android.R.color.holo_green_dark)
    }
}

...
`}</code></pre>
    <h3>{`Avatar Medium`}</h3>
    <h4>{`Define widget in your xml/programatically`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.avatar.LgnAvatarMedium
    android:id="@+id/lamAvatarMedium"
    ... />


`}</code></pre>
    <h4>{`Define widget in your fragment/activity.`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
binding.lamAvatarMedium.apply {
    avatar = LgnAvatarInitialMedium(context).apply {
        text = "Rizki Ilhami"
        textColor = context.requiredColor(UiKitAttrs.white)
    }
    badge = LgnAvatarBadgeMedium(context).apply {
        color = ContextCompat.getColor(context, android.R.color.holo_green_dark)
    }
}

...
`}</code></pre>
    <h3>{`Avatar Small`}</h3>
    <h4>{`Define widget in your xml/programatically`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.avatar.LgnAvatarSmall
    android:id="@+id/lamAvatarSmall"
    ... />

`}</code></pre>
    <h4>{`Define widget in your fragment/activity.`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
binding.lamAvatarSmall.apply {
    avatar = LgnAvatarInitialSmall(context).apply {
        text = "Rizki Ilhami"
        textColor = context.requiredColor(UiKitAttrs.white)
    }
    badge = LgnAvatarBadgeSmall(context).apply {
        color = ContextCompat.getColor(context, android.R.color.holo_green_dark)
    }
}

...
`}</code></pre>
    <div className="divi" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      